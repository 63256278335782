import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

class EULA extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    // const { handleToggle, sidebarOption } = this.state

    return (
      <Layout>
        <SEO title={"Eula"} titleDesc={""} titleKeywords={""} fbDes={"-"} twitDesc={"-"} seoLink={"/"} />

        <section className="">
          <article className="container pt-5">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="h2 my-4">EULA</h1>
              </div>
            </div>
          </article>
        </section>
        {/* <!-- ============== End Top Section ============== --> */}

        {/* <!-- Start of expert Section --> */}
        <section className="policy my-4">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <ol type="I">
                  <li>
                    <h5 className="c-blue">INTRODUCTION</h5>
                    <span>
                      <p>
                        Welcome to fuseclassroom.com (FC), a product of{" "}
                        <a href="https://fusemachines.com/" target="_blank">
                          Fusemachines Inc (FM).
                        </a>
                      </p>
                    </span>
                    <p>
                      FM starts every new subscriber relationship with a contract (the Agreement). The following
                      Agreement spells out what you can expect from us, and what FM expect from you when using the
                      Service and the FC Website.
                    </p>
                    <p>
                      FM may modify this Agreement from time to time and such modification shall be effective upon
                      posting by FM on the FC Website. You (the Customer) agree to be bound by any changes to this
                      Agreement when you use the Service and FC Website after any such modification is posted. It is
                      therefore important that you review this Agreement regularly to ensure you are aware of any
                      changes.
                    </p>
                  </li>
                  <li>
                    <h5 className="c-blue">ACCESS AND SERVICES</h5>
                    <p>
                      Your access to the service available on the FC Website depends on the level of service you select.
                      You may change or discontinue your account at any time. FM reserves the right to modify, suspend
                      or terminate access to the service at any time and for any reason without notice or refund,
                      including the right to require you to change your login identification code or password. FM also
                      reserves the right to delete all your Content (your text, files, works of authorship, or any other
                      of your materials) associated with your account and any other information you have on our system.
                    </p>
                  </li>
                  <li>
                    <h5 className="c-blue">TERM</h5>
                    <p>
                      This Agreement shall remain in full force and effect while you use the service or the FC Website.
                      You may terminate your account at any time and for any reason. FM may terminate your account at
                      any time, without warning. Even after your account is terminated, this Agreement will remain in
                      full effect.
                    </p>
                  </li>
                  <li>
                    <h5 className="c-blue">LICENCE CONDITIONS</h5>
                    <p>
                      Subject to the terms of this Agreement, FM grants the Customer a non-exclusive, non-transferable
                      license to use the service and the FM Background IP.
                    </p>
                    <p>
                      The Customer must not copy, alter, modify or reproduce the service except to the extent expressly
                      authorized by this Agreement.
                      <br />
                    </p>
                    <p>
                      The Customer acknowledges that there is no transfer of title or ownership to the Customer of the
                      Service or the FM Background IP under this Agreement.
                    </p>
                    <p>All Intellectual Property Rights in the Customer Content vests, on creation, in the Customer.</p>
                  </li>
                  <li>
                    <h5 className="c-blue">LICENSE FEES AND PAYMENT</h5>
                    <p>
                      It is free to open a Fusemachines Professional account and start using it. You are free to use the
                      account for 14 days only, at which point you will need to purchase either our SAAS model or pay a
                      license fee for white label solution to keep using the service.
                    </p>
                    <p>
                      FM will charge you a fee to purchase the service or other fees depending on the type of service
                      you have selected.
                    </p>
                    <p>
                      You should review the complete and current price list before registering an account for use of the
                      Service. You can cancel your account at any time, but you will not be entitled to a refund on
                      outstanding chat credits or any other payments which may have been made. FM reserves the right to
                      change our fees at any time and for any reason, but, whenever possible, FM will give you at least
                      one {"month's"} advance notice of such change.
                    </p>
                  </li>
                  <li>
                    <h5 className="c-blue">SYSTEM RULES</h5>
                    <p>
                      You agree to be bound by certain rules that are important for the proper use of the Service. Your
                      failure to follow these rules, whether listed below in this Agreement or made available on the FM
                      Website or communicated by any other means, may result in termination of your Service account. In
                      addition FM reserves the right to delete any Content that in the sole judgment of FM violates
                      these rules or the terms of this Agreement.
                    </p>
                  </li>
                  <ul>
                    <li>
                      <p>
                        When you register for a Service account, you will be asked to choose a password. You are
                        entirely responsible for maintaining the confidentiality of your password. You agree not to use
                        the account, username, or password of another Customer at any time or to disclose your password
                        to any third party. You agree to notify FM immediately if you suspect any unauthorized use of
                        your account or access to your password. You are solely responsible for any and all use of your
                        account. You must not tell others your username or password or let your Service account be used
                        by anyone except yourself.
                      </p>
                    </li>
                    <li>
                      <p>
                        You must not use the Service to commit a crime, or to plan, encourage or help others commit a
                        crime, or to undertake any illegal activity whatsoever.
                      </p>
                    </li>
                    <li>
                      <p>
                        FM RESERVES THE RIGHT TO INVESTIGATE, TAKE LEGAL OR ANY OTHER ACTION IT DEEMS APPROPRIATE, OR
                        TERMINATE YOUR ACCOUNT IF IN ITS SOLE OPINION AND AT ITS SOLE DISCRETION IT BELIEVES YOU HAVE
                        MISUSED THE SERVICE, OR HAVE BEHAVED IN ANY WAY WHICH FM REGARD AS INAPPROPRIATE, UNLAWFUL OR
                        ILLEGAL.
                      </p>
                    </li>
                  </ul>
                  <li>
                    <h5 className="c-blue">PRIVACY CONSIDERATIONS</h5>
                    <p>
                      FM may need to review or monitor your Content, therefore you should not expect to have a right to
                      privacy in any of your Content. FM reserve the right to copy and distribute to third parties any
                      information associated with your activities from use of the Service or the FC Website.
                    </p>
                  </li>
                  <li>
                    <h5 className="c-blue">TRADEMARKS AND COPYRIGHT</h5>
                  </li>
                  <ul>
                    <li>
                      <p>
                        “Fusemachines” is a trademark owned by Fusemachines Inc., registered in United States Patent and
                        Trademark Office and is therefore subject to national as well as international protection.
                      </p>
                    </li>
                    <li>
                      <p>
                        The FM service provider states that it has rights to intangible assets in the form of a graphic
                        project of offered services and software, website layout, computer software as well as to all
                        company signs, symbols and trademarks used within its scope of business activity.
                      </p>
                    </li>
                    <li>
                      <p>
                        All services and products offered by FM, which constitute specific works, are its exclusive
                        property and are covered by an act on copyright and neighboring rights
                      </p>
                    </li>
                    <li>
                      <p>
                        Website addresses as well as their contents and layouts are protected by the aforesaid acts.
                      </p>
                    </li>
                    <li>
                      <p>
                        Prior written consent of FM is required for any not permitted personal and non-personal use of
                        offered services and products. Such consent is required in particular when disseminating and
                        publicizing particular elements (e.g. photos, films, texts) in other Internet services, printed
                        publications, books, multimedia presentations and in other electronic media as well as for the
                        disposal or use of its work (adaptations, alterations, modifications)
                      </p>
                    </li>
                    <li>
                      <p>
                        Unauthorized lending, sale, granting of further licenses and sublicenses to the offered products
                        and services by the user without express consent of FM is prohibited. Such acts are not deemed
                        to be the proper use of services.
                      </p>
                    </li>
                    <li>
                      <p>
                        The user must not modify or change the purpose and use of offered services and products.
                        Misleading others as to the existence of cooperation, association, relationship or acting on
                        behalf of FM is prohibited.
                      </p>
                    </li>
                  </ul>
                  <li>
                    <h5 className="c-blue">LIMITATION OF LIABILITY</h5>
                  </li>
                  <ul>
                    <li>
                      <p>
                        FM guarantees the highest quality of its operations to ensure accessibility and continuity of
                        offered services and products in accordance with their use and purpose. No long distance data
                        transmission, however, guarantees 100% security, continuity and accessibility of the provided
                        services.
                      </p>
                      <p>
                        FM does not guarantee compatibility of offered services and products with other producers’
                        software. The user shall bear responsibility for the choice and consequences following from the
                        use of other software including its applicability to user’s objectives.
                      </p>
                    </li>
                    <li>
                      <p>
                        FM stipulates that opinions given by users do not reflect in any possible manner its views and
                        opinions. FM does not monitor or control users’ opinions on a continual basis; if, however, any
                        opinions in breach with these terms and conditions should be revealed efforts shall be
                        immediately made to remove them as soon as possible.
                      </p>
                    </li>
                    <li>
                      <p>FM shall bear no liability in particular for:</p>
                    </li>
                    <ul>
                      <li>
                        <p>All negative consequences being the result of force majeure,</p>
                      </li>
                      <li>
                        <p>
                          Phrases and entries added to the network in connection with the use of offered services and
                          products,
                        </p>
                      </li>
                      <li>
                        <p>
                          Unlawful and inconsistent with these terms and conditions users’ operations while using
                          offered services and products,
                        </p>
                      </li>
                      <li>
                        <p>Disturbances in accessibility of offered products and services not caused by FM,</p>
                      </li>
                      <li>
                        <p>
                          Damage suffered by the user arisen due to the suspension or closing the account by the user or
                          for other reasons arising from the user’s fault,
                        </p>
                      </li>
                      <li>
                        <p>
                          Damage suffered by the user as a result of a third party using his data that enable him to
                          access the provided services and products,
                        </p>
                      </li>
                      <li>
                        <p>
                          Damage following from the use or the impossibility to use offered products and services
                          including damage actually suffered, the loss of expected benefits, data loss, damage or
                          computer crash, the costs of substitute equipment and software, shut-down, company reputation
                          infringement,
                        </p>
                      </li>
                      <li>
                        <p>
                          Consequences of failure to perform or improper performance of obligations undertaken by other
                          users even though such an obligation has been undertaken using offered products and services.
                        </p>
                      </li>
                    </ul>
                  </ul>
                  <li>
                    <h5 className="c-blue">DISCLAIMERS</h5>
                    <p>
                      THE SERVICE AND FC WEBSITE ARE PROVIDED {'"AS IS"'} AND FM MAKE NO WARRANTIES, EXPRESS OR IMPLIED,
                      AS TO THE MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE, TITLE, NON-INFRINGEMENT OR ANY
                      OTHER WARRANTY, CONDITION, GUARANTY, OR REPRESENTATION, WHETHER ORAL, IN WRITING OR IN ELECTRONIC
                      FORM, INCLUDING BUT NOT LIMITED TO THE ACCURACY OR COMPLETENESS OF ANY INFORMATION CONTAINED
                      THEREIN OR PROVIDED BY THE SERVICE OR THE FC WEBSITE.
                    </p>
                    <p>
                      FM do not represent or endorse the accuracy, legitimacy, legality or reliability of any Content,
                      FM Content including FM Background IP, advice, opinion, statement or other information displayed,
                      uploaded or distributed through the Service by us, our partners or any registered account holder
                      or any other person or entity. You acknowledge that any reliance upon such Content, opinion,
                      account profile, FM Content, advice, statement or information is at your own risk. FM is not
                      responsible for the conduct, whether online or offline of users of the Service. Please use caution
                      and common sense when using the FC Website and the Service.
                    </p>
                  </li>
                  <li>
                    <h5 className="c-blue">FINAL PROVISIONS</h5>
                    <p>
                      All disputes arising in connection with these terms and conditions shall be primarily resolved
                      amicably. All disputes that cannot be amicably resolved shall be submitted for resolution by
                      Common Court of Law at the location of FM registered office. The provisions of Civil Code, the act
                      on copyright and neighboring rights, an act on electronic services providing and other provisions
                      of effective law shall be applicable in issues not governed by these terms and conditions.
                    </p>
                  </li>
                  <li>
                    <h5 className="c-blue">DEFINITIONS</h5>
                    <p>
                      Content means Customer content consisting of question and answer pairs, text, files, works of
                      authorship, or any other materials that are unique to the Customer and chat paths unique to the
                      Customer and that do not contain any FM Background IP.
                      <br />
                      Intellectual Property Rights means all present and future intellectual and industrial property
                      rights throughout the world, including all rights in respect of:
                    </p>
                  </li>
                  <ul>
                    <li>
                      <p>
                        Inventions, discoveries and novel designs (whether or not registered or registrable as patents
                        or designs) including developments or improvements of equipment, products, technology,
                        processes, methods or techniques;
                      </p>
                    </li>
                    <li>
                      <p>
                        Copyright (including future copyright) throughout the world in all literary works, artistic
                        works, computer software and any other works or subject matter in which copyright subsists and
                        may in the future subsist;
                      </p>
                    </li>
                    <li>
                      <p>
                        Obligations to have confidential information and trade secrets kept confidential and secret;
                      </p>
                    </li>
                    <li>
                      <p>
                        Trade and service marks (whether registered or unregistered), business names, trade names,
                        domain names, logos and get -up
                      </p>
                    </li>
                    <li>
                      <p>License Fee means the fees payable by the Customer to FM as specified in the Agreement</p>
                    </li>
                    <li>
                      <p>
                        FM Background IP means generic systems, processes, content, templates and user interfaces FM has
                        developed or develops for the Customer business sector or similar sectors. This FM Background IP
                        refers to generic systems, processes, content, templates and user interfaces FM has developed or
                        develops which could be applicable across the business sector or similar sectors in which the
                        Customer operates. This FM Background IP includes any software, databases and their contents,
                        files, designs, audio-visual material, training materials, documents, or other information, in
                        hard-copy or electronic form whether delivered online or in portable media formats; and any
                        information or concepts communicated orally, in writing or in audio visual format relating to
                        Fusemachines design, build, content, processes and/or integration. FM owns and retains ownership
                        of all FM Background IP
                      </p>
                    </li>
                    <li>
                      <p>Party means either FM or the Customer as the context dictates</p>
                    </li>
                    <li>
                      <p>Publish means to create, display, post or publish any Content using the Service</p>
                    </li>
                  </ul>
                  <li>
                    <h5 className="c-blue">ACKNOWLEDGMENT</h5>
                    <p>
                      This Agreement represents the entire understanding between you and FM regarding your use of the
                      Service and the FC Website and supersedes any prior statements or representations.
                    </p>
                    <p>
                      If you do not agree to the terms of this online Subscriber Agreement, you should leave the FM
                      Website and discontinue use of the Service immediately.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End of expert Section --> */}
      </Layout>
    )
  }
}

export default EULA
